<template>
  <div class="container">
    <b-overlay :show="loading" rounded="sm">
      <form
        @submit.prevent="
          proyectoId ? handleUpdateDesarrollo() : handleAddDesarrollo()
        "
      >
        <div class="row col-12">
          <div class="col-6">
            <b-form-group label="Nombre">
              <b-form-input v-model="proyecto.nombre" type="text" required>
              </b-form-input>
            </b-form-group>
          </div>
          <div class="col-6">
            <b-form-group label="Nombre inglés">
              <b-form-input v-model="proyecto.nombreIngles" type="text" required>
              </b-form-input>
            </b-form-group>
          </div>
          <div class="col-6">
            <b-form-group label="Categoria">
              <!-- <b-form-input v-model="proyecto.categoria" type="text" required>
              </b-form-input> -->
              <b-form-select
                v-model="proyecto.categoria"
                :options="categorias"
                class="mb-3"
                value-field="id"
                text-field="nombre"
                required
              ></b-form-select>
            </b-form-group>
          </div>
          <div class="col-6">
            <b-form-group label="Direccion">
              <b-form-input v-model="proyecto.direccion" type="text" required>
              </b-form-input>
            </b-form-group>
          </div>
        </div>
        <div class="row col-12">
          <label>Descripcion</label>
          <vue-editor v-model="proyecto.descripcion"></vue-editor>
        </div>
        <div class="row col-12">
          <label>Descripcion inglés</label>
          <vue-editor v-model="proyecto.descripcionIngles"></vue-editor>
        </div>
        <div class="row col-12">
          <h5 class="mt-3">Galeria</h5>
        </div>
        <div class="row col-12" :key="formInputkey">
          <b-form-file
            v-model="imagen"
            :state="Boolean(imagen)"
            placeholder="Arrastra una imagen aqui..."
            drop-placeholder="Drop file here..."
            accept=".jpg, .jpeg, .png"
            @input="imageUploaded($event)"
          ></b-form-file>

          <div class="row col-12 d-flex justify-content-between mt-2">
            <div
              v-for="(imagen, index) in proyecto.galeria"
              :key="index"
              class="d-flex flex-column justify-content-between"
            >
              <div>
                <img
                  :src="imagen.url"
                  alt="responsive image"
                  width="300px"
                  height="300px"
                />
              </div>
              <div class="mt-1 mb-1">
                <b-button
                  variant="danger"
                  size="sm"
                  @click="
                    !imagen.hasOwnProperty('path')
                      ? removeImageLocal(index)
                      : removeImageDb(imagen, index)
                  "
                  >Eliminar</b-button
                >
              </div>
              <div>
                <b-form-group label="Posicion">
                  <b-form-input v-model.number="imagen.posicion" type="number">
                  </b-form-input>
                </b-form-group>
              </div>
            </div>
          </div>
        </div>
        <!-- PDF ESPAÑOL -->
        <template>
          <div class="row col-12">
            <h5 class="mt-3">PDF</h5>
          </div>
          <div class="row col-12" v-if="!proyecto.pdf">
            <b-form-file
              v-model="proyecto.pdf"
              :state="Boolean(proyecto.pdf)"
              placeholder="Arrastra un pdf aqui..."
              drop-placeholder="Drop file here..."
              accept=".pdf"
            ></b-form-file>
          </div>
          <div class="row col-12 d-flex justify-content-between" v-else>
            <div
              class="d-flex flex-column justify-content-between align-items-start"
            >
              <b-icon icon="file-earmark-font"></b-icon>
              <a :href="proyecto.pdf.url" target="_blank">Archivo Adjunto</a>
            </div>
            <div>
              <b-button
                variant="danger"
                size="sm"
                @click="
                  proyecto.pdf && !proyecto.pdf.hasOwnProperty('path')
                    ? (proyecto.pdf = null)
                    : removePdf(proyecto.pdf,'pdf')
                "
                >Eliminar PDF</b-button
              >
            </div>
          </div>
        </template>
        <!-- PDF INGLES -->
        <template>
          <div class="row col-12">
            <h5 class="mt-3">PDF inglés</h5>
          </div>
          <div class="row col-12" v-if="!proyecto.pdfIngles">
            <b-form-file
              v-model="proyecto.pdfIngles"
              :state="Boolean(proyecto.pdfIngles)"
              placeholder="Arrastra un pdf aqui..."
              drop-placeholder="Drop file here..."
              accept=".pdf"
            ></b-form-file>
          </div>
          <div class="row col-12 d-flex justify-content-between" v-else>
            <div
              class="d-flex flex-column justify-content-between align-items-start"
            >
              <b-icon icon="file-earmark-font"></b-icon>
              <a :href="proyecto.pdfIngles.url" target="_blank">Archivo Adjunto</a>
            </div>
            <div>
              <b-button
                variant="danger"
                size="sm"
                @click="
                  proyecto.pdfIngles && !proyecto.pdfIngles.hasOwnProperty('path')
                    ? (proyecto.pdfIngles = null)
                    : removePdf(proyecto.pdfIngles,'pdfIngles')
                "
                >Eliminar PDF</b-button
              >
            </div>
          </div>
        </template>
        <!--Botones guardar y actualizar-->
        <div class="row col-12 d-flex align-items-center mt-2">
          <b-button type="submit" variant="primary" size="md">
            {{ proyectoId ? "Actualizar" : "Crear" }}
          </b-button>
        </div>
      </form>
    </b-overlay>
  </div>
</template>

<script>
import { VueEditor } from "vue2-editor";
import { mapActions, mapGetters } from "vuex";
import {
  incrementPositionAndRemove,
  getHighestPositionInArray,
} from "@/helpers/arrays";
export default {
  props: {
    proyectoId: {
      type: String,
      required: false,
    },
  },
  components: {
    VueEditor,
  },
  data() {
    return {
      loading: false,
      formInputkey: 0,
      position: 1,
      imagen: null,
      proyecto: {
        nombre: "",
        nombreIngles:'',
        direccion: "",
        categoria: "",
        descripcion: "",
        descripcionIngles:'',
        galeria: [],
        pdf: null,
        pdfIngles:null,
        posicion: null,
      },
    };
  },
  methods: {
    ...mapActions({
      saveProyecto: "comercializacion/saveProyecto",
      getProyecto: "comercializacion/getProyecto",
      deleteProyectoImage: "comercializacion/deleteProyectoImage",
      updateProyecto: "comercializacion/updateProyecto",
      deletePdf: "comercializacion/deletePdf",
      getCategorias: "categorias/getCategorias",
    }),
    imageUploaded(imagen) {
      let url = URL.createObjectURL(imagen);
      this.proyecto.galeria.push({ imagen, posicion: this.position, url });
      this.imagen = null;
      this.position++;
      this.formInputkey++;
    },

    async handleAddDesarrollo() {
      this.loading = true;
      const res = await this.saveProyecto(this.proyecto);
      if (res) {
        this.$swal("Exito", "Desarrollo creado", "success");
        this.loading = false;
        this.$router.push({ name: "Admin Comercializacion" });
      } else {
        this.$swal("Error", "Error al crear el desarrollo", "error");
        this.loading = false;
      }
    },
    async handleUpdateDesarrollo() {
      this.loading = true;
      const res = await this.updateProyecto(this.proyecto);
      if (res) {
        this.$swal("Exito", "Proyecto actualizado", "success");
        this.loading = false;
        this.$router.push({ name: "Admin Comercializacion" });
      } else {
        this.$swal("Error", "Error al actualizar el proyecto", "error");
        this.loading = false;
      }
    },
    removeImageLocal(index) {
      this.proyecto.galeria = incrementPositionAndRemove(
        this.proyecto.galeria,
        index
      );
      this.position--;
    },
    async removeImageDb(image, index) {
      const res = await this.deleteProyectoImage(image);
      if (res) {
        this.$swal("Exito", "Imagen eliminada", "success");
        this.proyecto.galeria.splice(index, 1);
      } else {
        this.$swal("Error", "Error al eliminar la imagen", "error");
      }
    },
    async removePdf(pdf,propiedad) {
      const res = await this.deletePdf({
        path:pdf.path,
        propiedad,
      });
      if (res) {
        pdf = null;
        this.proyecto[propiedad] = null
        this.$swal("Exito", "Pdf eliminado", "success");
      } else {
        this.$swal("Error", "Error al eliminar el pdf", "error");
      }
    },
  },
  computed: {
    ...mapGetters({
      proyectodb: "comercializacion/proyecto",
      categorias: "categorias/categoriasComercializacion",
    }),
  },

  async mounted() {
    await this.getCategorias();
    if (this.proyectoId) {
      await this.getProyecto(this.proyectoId);
      this.proyecto = JSON.parse(JSON.stringify(this.proyectodb));
      this.position = getHighestPositionInArray(this.proyecto.galeria) + 1;
    }
  },
};
</script>

<style scoped>
.quillWrapper {
  width: 100%;
  height: 100%;
}
</style>
